@use "variables" as v;

@font-face {
  font-family: "Inter-self";
  font-style: normal;
  font-weight: 300 800;
  font-display: swap;
  src: url("/assets/fonts/Inter.woff2") format("woff2");
}

@font-face {
  font-family: "Spartan MB Logo";
  src: url("/assets/fonts/SpartanMB-ExtraBold-logo.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  height: 100%;
  height: 100dvh;
  display: flex;
}

body {
  min-height: 100%;
  min-height: 100dvh;
  height: auto;
  flex: 1 1 auto;
}

html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
  background: v.$background;
  font-family: "Inter-self", sans-serif;
  color: v.$text;
  overscroll-behavior: none;
}

@media (min-width: v.$tablet) {
  html,
  body {
    font-size: 15px;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
  scrollbar-color: #b3b3b3 #f0f0f0;
  scrollbar-width: thin;
}

button,
input,
p {
  font-family: "Inter-self", sans-serif;
}

a {
  color: v.$primary;
  text-decoration: none;
}

/* input placeholder */

::placeholder {
  color: v.$text;
  opacity: 1;
}

::placeholder {
  font-size: 15px !important;
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

/* icons */

.icon {
  display: inline-block;
  width: 1.8em;
  height: 1.8em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* disable blue Chrome focus */

*:focus {
  outline: 0;
}

/* scrollbar for safari */

body {
  scrollbar-arrow-color: rgb(196, 196, 196);
  scrollbar-track-color: rgb(250, 250, 250);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(140, 140, 140, 0.8);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(140, 140, 140, 1);
}

::-webkit-scrollbar-track {
  background-color: rgba(206, 206, 206, 1);
}

/* infocard and my parcel svg image */

.infocard__image svg,
.omgevingswet__parcelimage svg,
.card__summary__image svg {
  width: 55px;
  height: 55px;
}

@media (min-width: v.$mobile) {
  .infocard__image svg,
  .omgevingswet__parcelimage svg,
  .card__summary__image svg {
    width: 75px;
    height: 75px;
  }
}

.card__image svg {
  width: 120px;
  height: 120px;
}

/* mat overlay */

body .cdk-overlay-container {
  z-index: v.$z-level-80;
}

/* mat dialog */

html {
  --mdc-dialog-subhead-size: 16px;
}

.mdc-dialog__surface {
  border-radius: v.$radius !important;
}

.layertoggles .mdc-dialog__surface {
  border-radius: 0 !important;
}

@media (max-width: v.$tablet) {
  .measuring .mdc-dialog__surface,
  .downloading .mdc-dialog__surface {
    border-radius: v.$radius v.$radius 0 0 !important;
  }
}

@media (min-width: v.$tablet) {
  .layertoggles .mdc-dialog__surface {
    width: 390px;
    max-width: 100%;
    border-radius: v.$radius 0 0 v.$radius !important;
  }
}

body .custom-dialog {
  max-width: 93vw !important;
}

.custom-dialog .mat-mdc-dialog-container {
  max-width: 620px;
}

.legend-dialog.custom-dialog .mat-mdc-dialog-container {
  max-width: 100%;
}

.hints-dialog.custom-dialog .mat-mdc-dialog-container {
  max-width: 660px;
}

.upgrade-dialog.custom-dialog .mat-mdc-dialog-container {
  max-width: 550px;
}

.measuring.custom-dialog .mat-mdc-dialog-container,
.downloading.custom-dialog .mat-mdc-dialog-container {
  max-width: 786px;
}

.custom-dialog .mdc-dialog__surface {
  background-color: v.$background !important;
}

.custom-dialog .mat-mdc-dialog-title {
  margin: 0;
  padding: 14px 20px;
  background: v.$primary;
  color: #fff !important;
}

.custom-dialog .mat-mdc-dialog-title:before {
  display: none;
}

.custom-dialog .mat-mdc-dialog-content {
  margin: 0;
  padding: 16px !important;
  color: v.$text !important;
  letter-spacing: 0 !important;
}

.custom-dialog p {
  color: #484848;
}

.custom-dialog .mat-mdc-dialog-actions {
  gap: 15px 6px;
  padding: 0 16px 16px;
}

body .custom-dialog .mat-button-focus-overlay {
  opacity: 0 !important;
}

body .custom-dialog .mat-form-field-underline {
  background-color: #dadada;
}

/* full image gallery */

.full-image-gallery {
  background: #fff;
}

/* mat upgrade dialog */

body .larger-dialog {
  max-width: 93vw !important;
}

body .darker-backdrop {
  background: rgba(0, 0, 0, 0.8);
}

/* mat button */

html {
  --mdc-text-button-label-text-color: v.$text;
  --mdc-protected-button-label-text-color: v.$text;
  --mdc-outlined-button-label-text-color: v.$text;
}

body .mat-mdc-raised-button {
  font-weight: 800;
  padding: 3px 13px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1),
    0px 2px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.1) !important;
}

@media (min-width: v.$tablet) {
  body .mat-mdc-raised-button {
    padding: 3px 17px;
  }
}

body .mat-mdc-button,
body .mat-mdc-raised-button,
body .mat-mdc-outlined-button
{
  border-radius: v.$radius;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 50px;
}

.mdc-button__label {
  display: flex;
  gap: 8px;
  align-items: center;
}

/* mat button toggle */

html {
  --mat-standard-button-toggle-selected-state-background-color: #f3f3f3;
  --mat-standard-button-toggle-shape: 15px;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  top: -8px !important;
  width: 11px !important;
  color: v.$primary !important;
}

body .mat-button-toggle .mat-pseudo-checkbox {
  margin-right: 5px;
}

/* mat snackbar */

.styled-snackbar .mdc-snackbar__surface {
  background: v.$background !important;
}

.styled-snackbar .mdc-snackbar__label {
  color: v.$text !important;
  font-size: 13px !important;
}

@media (min-width: v.$mobile) {
  .styled-snackbar .mdc-snackbar__label {
    font-size: 14px !important;
  }
}

.styled-snackbar.mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: v.$primary !important;
}

/* mat list */

.user-details .mat-list-base .mat-list-item .mat-list-item-content {
  padding: 0;
}

/* mat forms general */

html {
  --mdc-outlined-text-field-container-shape: 15px;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.18);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  min-height: 57px;
  background: #fff;
  border-radius: v.$radius;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 17px;
}

.mat-mdc-form-field-subscript-wrapper {
  letter-spacing: 0;
}

.mat-mdc-form-field-error {
  margin-top: 1px;
}

.mat-mdc-form-field-icon-suffix {
  padding: 0 8px 0 4px !important;
}

/* mat checkbox */

html {
  --mdc-checkbox-state-layer-size: 20px;
  --mat-checkbox-label-text-color: v.$text;
}

.mat-mdc-checkbox .mdc-form-field {
  align-items: start;
}

.mat-mdc-checkbox label {
  margin-left: 7px !important;
  font-size: 14px;
}

@media (min-width: v.$tablet) {
  .mat-mdc-checkbox label {
    font-size: 15px;
  }
}

.mat-mdc-checkbox-touch-target {
  height: 32px;
}

/* mat select */

html {
  --mat-select-trigger-text-line-height: 21px;
}

/* mat radio */

.mat-mdc-radio-button {
  --mdc-radio-selected-icon-color: #008cba !important;
  --mdc-radio-selected-focus-icon-color: #008cba !important;
  --mdc-radio-selected-hover-icon-color: #008cba !important;
  --mat-radio-label-text-color: #424242;
}

/* mat slide toggle */

body .mat-mdc-slide-toggle .mdc-label {
  margin-left: 7px;
  font-size: 14px;
  color: v.$text;
}

@media (min-width: v.$mobile) {
  body .mat-mdc-slide-toggle .mdc-label {
    font-size: 15px;
  }
}

/* mat slider */

.mdc-slider__value-indicator {
  white-space: nowrap;
}

.mdc-slider__value-indicator-text {
  font-size: 12px !important;
}

/* mat tabs */

html {
  --mat-tab-header-label-text-weight: 300 !important;
}

.mdc-tab {
  border: 1px solid v.$border !important;
  border-width: 0 1px 0 0 !important;
}

.infocard .mdc-tab,
.omgevingswet .mdc-tab {
  border-width: 1px 1px 1px 0 !important;
}

.mdc-tab--active {
  background-color: #fff !important;
}

.mdc-tab__text-label {
  position: relative;
  top: -2px;
}

/* mat tooltip */

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-tracking: 0;
}

/* mat card */

body .mdc-card__actions {
  flex-direction: column;
}

/* mat expansion */

html {
  --mat-expansion-container-shape: 10px;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 400;
}

@media(min-width: v.$tablet) {
  html {
    --mat-expansion-header-text-size: 15px;
  }
}

.mat-expansion-panel-header {
  padding: 15px 20px !important;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1),
    0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 5px 0px rgba(0, 0, 0, 0.06);
}

.mat-expansion-indicator {
  position: relative;
  top: -3px;
}

.mat-expanded .mat-expansion-indicator {
  top: 3px;
}

/* paginator */

.mat-mdc-paginator button {
  display: flex;
}

/* pricing */

.productdetail .product__price__discount__discount__text {
  top: -12px !important;
}

/* order in checkout */

body .order__items .order__empty {
  margin: 8px 0 20px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: v.$radius;
  text-align: center;
}

body .checkout .order__list {
  max-height: none;
}

.checkout .order__mapbutton {
  display: none;
}

/* disable scrolling for fixed menutray backdrop when searching */

body.search {
  overflow: hidden;
}

body.disable-scroll {
  overflow: hidden;
}

/* product preview modal */

.preview-modal {
  overflow: auto;
  height: auto;
  max-width: 100% !important;
  max-height: 100%;
  overscroll-behavior: none;
}

@media (min-width: v.$desktop) {
  .preview-modal {
    max-width: 95vw !important;
    max-height: 95dvh;
  }
}

@media (min-width: 1400px) {
  .preview-modal {
    max-width: 76vw !important;
  }
}

@media (max-width: v.$tablet) {
  body .preview-modal .productdetail__faq {
    margin: 30px 20px;
  }
}

.preview-modal .mdc-dialog__surface {
  border-radius: 0 !important;
}

@media (min-width: v.$desktop) {
  .preview-modal .mdc-dialog__surface {
    border-radius: v.$radius !important;
  }
}

/* lists in product detail text, class is introduced in database text */

.preview__description_list {
  padding: 0 0 0 17px;
}

/* parcel based shop */

.shop__header__title__addresses .addresses__expandable {
  display: inline;
}

.shop__header__title__addresses .addresses__chevron {
  right: 3px;
}

/* blog */

.single__text p {
  line-height: 24px;
}

.single__text h2 {
  margin-bottom: 5px;
  font-size: 13px;
}

@media (min-width: v.$tablet) {
  .single__text h2 {
    font-size: 14px;
  }
}

.single__text h4 {
  margin-bottom: 5px;
}

.single__text img {
  max-width: 100%;
  margin-top: 5px;
}

/* leaflet */

.leaflet-container {
  font-family: "Inter-self", sans-serif;
  font-display: auto;
  font-size: 14px !important;
}

@media (min-width: v.$tablet) {
  .leaflet-container {
    font-size: 15px !important;
  }
}

.leaflet-top,
.leaflet-bottom {
  z-index: v.$z-level-0;
}

img.leaflet-tile {
  outline: 1px solid transparent;
  mix-blend-mode: plus-lighter;
}

body app-parcel .leaflet-bottom .leaflet-control-zoom {
  margin-bottom: 104px;
}

body .leaflet-container .leaflet-control-zoom {
  border: none;
}

.leaflet-control-zoom {
  display: none;
}

@media (min-width: v.$tablet) {
  .leaflet-control-zoom {
    display: block;
  }
}

body .leaflet-container .leaflet-control-zoom.leaflet-bar {
  box-shadow: none;
}

body .leaflet-container .leaflet-control-zoom.leaflet-bar a {
  background: #eee;
  background: rgba(249, 249, 249, 0.95);
  border: 1px solid v.$border-dark;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 35px;
}

body .leaflet-container .leaflet-control-zoom.leaflet-bar a:first-of-type {
  margin-bottom: 9px;
}

/* leaflet tooltips */

.leaflet-tooltip {
  max-width: 450px !important;
  padding: 5px 10px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-weight: 800;
  border-radius: v.$radius;
}

.parcel-description-tooltip,
.parcel-reports-tooltip,
.parcel-transaction-tooltip {
  margin: 0;
  padding: 5px;
  font-weight: 400;
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}

.parcel-transaction-tooltip span {
  margin-bottom: 4px;
  padding-bottom: 6px;
  font-weight: 800;
  display: block;
  border-bottom: 1px solid #eee;
}

.parcel-transaction-tooltip .transaction {
  margin-bottom: 0;
}

.parcel-transaction-tooltip .transaction span {
  margin-bottom: 3px;
  padding-bottom: 0;
  color: #666;
  font-weight: 400;
  border: none;
}

.parcel-transaction-tooltip__info {
  margin-top: 5px;
  padding-top: 6px;
  border-top: 1px solid #eee;
}

.leaflet-tooltip-related {
  display: flex;
  height: 31px;
  width: 31px;
  align-items: center;
  justify-content: center;
  padding: 2px 7px;
  border-radius: 100%;
}

/* disable scroll on header when menu is open */

.disable-scroll .header {
  top: 0 !important;
}

/* swiper */

html {
  --swiper-pagination-bottom: -10px;
}

@media (min-width: v.$tablet) {
  html {
    --swiper-pagination-bottom: 8px;
  }
}

/* geocoder */

.geocoder-container,
.geocoder-search__input {
  font-size: 14px !important;
}

@media (min-width: v.$tablet) {
  .geocoder-container,
  .geocoder-search__input {
    font-size: 15px !important;
  }
}

.pulse-icon .pulse {
  width: 20px;
  height: 20px;
  opacity: 0;
  background: rgba(255, 0, 0, 0.6);
  border-radius: 50%;
  position: relative;
  animation: pulse 0.5s ease-out 1;
}

/* Keyframes for the pulse animation */
@keyframes pulse {
  0% {
    background: v.$primary;
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    background: v.$primary;
    transform: scale(1.5);
    opacity: 0;
  }
}